import { http } from "./axiosConfig";

/**
 * Llama a la API para hacer re-login de un Buyer o Supplier
 * @param {Object} params - Parámetros de la petición
 * @param {string} params.email - Email de la cuenta
 * @param {string} params.accountType - "buyers" o "suppliers"
 */
export const reloginService = async (email: string, accountType: "buyers" | "suppliers") => {
  console.log("📡 reloginService se ejecutó con:", email, accountType);

  const response = await http.post(`/api/actions/relogin/`, { email, accountType });

  console.log("✅ Respuesta recibida:", response.data);
  return response.data;
};
