import axios from "axios";

const instance = axios.create({
  // baseURL: "http://localhost:3000", // Cambia esto para producción
  baseURL: "https://fctransfertool.com", // Cambia esto para producción
  withCredentials: true,
  headers: {
    "content-type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

// 🔥 Interceptor para agregar el token JWT a todas las solicitudes
instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("authToken"); // Obtiene el token almacenado
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const http = instance; // 🔥 Exportamos `http` para usar en los services
