import { useMutation, useQueryClient } from "react-query";
import { changeStatus } from "../../../app/services/supplierServices/changeStatusService";
import toast from "react-hot-toast";

const useChangeStatus = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ id, accountType }: { id: string; accountType: "buyers" | "suppliers" }) =>
      changeStatus(id, accountType),
    {
      onSuccess: () => {
        // toast.success("Status changed to ORDER COMPLETED"); // Notificación de éxito

        // 🔄 Invalidar y refrescar la lista de cuentas después del cambio
        queryClient.invalidateQueries("buyerAccounts");
        queryClient.invalidateQueries("supplierAccounts");
      },
      onError: (error) => {
        console.error("❌ Error changing status:", error);
        // toast.error("Failed to change status");
      },
    }
  );
};

export default useChangeStatus;
