import { http } from "./axiosConfig";

export const resumeAccount = async (id: string, accountType: "buyers" | "suppliers") => {
  try {
    const response = await http.put(`/api/actions/resume/${accountType}/${id}`);
    return response.data;
  } catch (error) {
    console.error("❌ Error en changeStatus:", error);
    throw error;
  }
};


