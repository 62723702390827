import { http } from '../axiosConfig'

export const reloginInEditBuyer = async (formData: any) => {
  try {
    const url = `/api/actions/reloginInEdit/buyers`
    const response = await http.put(url, formData)
    return response.data
  } catch (error) {
    console.error('Error al hacer reloginInEdit:', error)
    throw error
  }
}
