import { useState } from 'react';
import { createPortal } from 'react-dom';
import { Modal } from 'react-bootstrap';
import { useMutation, useQueryClient } from 'react-query';
import Swal from 'sweetalert2';
import { KTIcon } from '../../../helpers';
import { uploadCSV } from '../../../../app/services/uploadCSV';

type Props = {
  show: boolean;
  handleClose: () => void;
};

const modalsRoot = document.getElementById('root-modals') || document.body;

const BulkUploadCSVModalSupplier = ({ show, handleClose }: Props) => {
  const [file, setFile] = useState<File | null>(null);
  const queryClient = useQueryClient();

  // ✅ React Query Mutation para subir CSV
  const uploadMutation = useMutation(uploadCSV, {
    onSuccess: (response) => {
      Swal.fire({
        icon: 'success',
        title: 'File uploaded successfully', 
        text: response.message, // Mensaje rápido del backend
        showConfirmButton: false,
        timer: 1500,
        background: '#1e1e1e',
        color: '#ffffff',
      });
  
      handleClose(); // Cierra el modal rápido
      queryClient.invalidateQueries('supplierAccounts');
    },
    onError: () => {
      Swal.fire({
        icon: 'error',
        title: 'File upload failed',
        text: 'Hubo un error al enviar el archivo.',
        background: '#1e1e1e',
        color: '#ffffff',
      });
    },
  });

  // 📂 Manejar selección de archivo
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setFile(event.target.files[0]);
    }
  };

  // 🚀 Manejar envío del formulario
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!file) {
      Swal.fire({
        icon: 'warning',
        title: 'No file selected',
        text: 'Please select a CSV file to upload.',
        background: '#1e1e1e',
        color: '#ffffff',
      });
      return;
    }
  
    const formData = new FormData();
    formData.append('file', file);
  
    // ✅ Cierra el modal inmediatamente después de subir el archivo
    handleClose();
  
    uploadMutation.mutate(formData);
  };
  
  

  return createPortal(
    <Modal
      id='kt_modal_upload_csv'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-600px'
      show={show}
      onHide={handleClose}
      backdrop={true}
    >
      <div className='modal-header'>
        <h2>Upload CSV File</h2>
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          <KTIcon className='fs-1' iconName='cross' />
        </div>
      </div>
      <div className='modal-body py-lg-10 px-lg-10'>
        <form onSubmit={handleSubmit}>
          <div className='mb-4'>
            <label className='form-label'>Select CSV File</label>
            <input type='file' className='form-control' accept='.csv' onChange={handleFileChange} />
          </div>
          <div className='text-center'>
            <button type='submit' className='btn btn-lg btn-primary' disabled={uploadMutation.isLoading}>
              {uploadMutation.isLoading ? 'Uploading...' : 'Upload'}
            </button>
          </div>
        </form>
      </div>
    </Modal>,
    modalsRoot
  );
};

export { BulkUploadCSVModalSupplier };
