import { useQuery } from "react-query";
import { getAllTransferSessions } from "../../app/services/transferSessionServices";

const useTransferSessions = () => {
  return useQuery("transferSessions", getAllTransferSessions, {
    staleTime: 1000 * 60 * 5, // Cache por 5 minutos
  });
};

export default useTransferSessions;
