import { http } from "./axiosConfig"; // Asegúrate de que esta es tu configuración de Axios

export const uploadCSV = async (formData: FormData) => {
  try {
    const response = await http.post("/api/supplierAccounts/addMultipleSupplierAccount", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return response.data;
  } catch (error) {
    console.error("❌ Error subiendo el archivo CSV:", error);
    throw error;
  }
};
