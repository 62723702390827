import React from "react";
import { createPortal } from "react-dom";
import { Modal } from "react-bootstrap";
import useBuyerTransactions from "../../../hooks/useBuyerTransactions";
import { KTIcon } from "../../../helpers"; // Asegúrate de importar los íconos de Metronic

const modalsRoot = document.getElementById("root-modals") || document.body;

const BuyerTransactionsModal = ({ show, handleClose, buyerId }) => {
  const { data: transactions, isLoading, isError } = useBuyerTransactions(buyerId);

  // 🔥 Filtrar SOLO transacciones donde el `buyerId` coincida
  const filteredTransactions = Array.isArray(transactions)
  ? transactions.filter((tx) => tx.buyerId === buyerId)
  : [];

  // Ordenar transacciones por `createdAt` (de más temprano a más tarde)
  const sortedTransactions = [...filteredTransactions].sort(
    (a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
  );

  console.log("Filtered Transactions:", sortedTransactions);

  return createPortal(
    <Modal
      show={show}
      onHide={handleClose}
      dialogClassName="modal-dialog modal-dialog-centered mw-800px"
      backdrop="static"
    >
      <div className="modal-header">
        <h2 className="modal-title text-white">Transaction History</h2>
        <button
          type="button"
          className="btn btn-sm btn-icon btn-active-light-primary"
          onClick={handleClose}
        >
          <KTIcon iconName="cross" className="fs-2" />
        </button>
      </div>

      <div className="modal-body py-5 px-10">
        {isLoading && <p className="text-center text-white">Loading transactions...</p>}
        {isError && <p className="text-center text-danger">Error loading transactions</p>}
        {!isLoading && !isError && sortedTransactions.length === 0 && (
          <p className="text-center text-warning">No transactions found.</p>
        )}

        {!isLoading && !isError && sortedTransactions.length > 0 && (
          <div className="table-responsive">
            <table className="table table-hover table-rounded border-gray-300 gy-3">
              <thead>
                <tr className="fw-bold fs-6 text-gray-800 border-bottom border-gray-300">
                  <th className="text-center">Type</th>
                  <th className="text-center">Player</th>
                  <th className="text-center">Price</th>
                  <th className="text-center">Coins Before</th>
                  <th className="text-center">Coins After</th>
                  <th className="text-center">Date</th>
                </tr>
              </thead>
              <tbody>
                {sortedTransactions.map((tx) => (
                  <tr key={tx.transactionId} className="text-white">
                    <td className="text-center">
                      <span
                        className={`badge ${
                          tx.transactionType === "BUY"
                            ? "badge-light-success"
                            : "badge-light-danger"
                        }`}
                      >
                        {tx.transactionType}
                      </span>
                    </td>
                    <td className="text-center">{tx.playerName}</td>
                    <td className="text-center">{tx.price.toLocaleString()}</td>
                    <td className="text-center">{tx.coinsBefore.toLocaleString()}</td>
                    <td className="text-center">{tx.coinsAfter.toLocaleString()}</td>
                    <td className="text-center">
                      {new Date(tx.createdAt).toLocaleTimeString([], {
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                        hour12: true, // Cambiar a false si quieres formato 24h
                      })}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </Modal>,
    modalsRoot
  );
};

export default BuyerTransactionsModal;