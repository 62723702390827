import { useMutation, useQueryClient } from "react-query";
import { deleteBuyerAccount } from "../../../app/services/buyerServices/deleteBuyerAccountServices";

const useDeleteBuyerAccount = () => {
  const queryClient = useQueryClient();

  return useMutation(deleteBuyerAccount, {
    onSuccess: () => {
      queryClient.invalidateQueries("buyerAccounts");
    },
  });
};

export default useDeleteBuyerAccount;
