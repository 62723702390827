/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {SidebarMenuItem} from './SidebarMenuItem'

const SidebarMenuMain = () => {

  return (
    <>
      <SidebarMenuItem
        to='/tables'
        icon='address-book'
        title='Orders'
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem to='/suppliers' icon='user' title='Suppliers' fontIcon='bi-layers' />
      <SidebarMenuItem
        to='/accounts'
        icon='abstract-26'
        title='Accounts'
        // fontIcon='ki-user'
      />
      <SidebarMenuItem
        to='/faqs'
        icon='message-text-2'
        title='FAQs'
        fontIcon='bi-layers'
      />
    </>
  )
}

export {SidebarMenuMain}
