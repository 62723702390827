// hooks/frontendActions/useHideBuyerAccount.js
import { useMutation, useQueryClient } from 'react-query'
import { http } from '../../../app/services/axiosConfig'

const hideSupplierAccount = async (id) => {
   
  const response = await http.put(`/api/supplierAccounts/removeSupplier/${id}`)
  return response.data
}

const useHideSupplierAccount = () => {
  const queryClient = useQueryClient()

  return useMutation(hideSupplierAccount, {
    onSuccess: () => {
      queryClient.invalidateQueries('supplierAccounts')
    },
  })
}

export default useHideSupplierAccount
