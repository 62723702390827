import { useQuery } from "react-query";
import { fetchSupplierAccounts } from "../../app/services/supplierServices/supplierAccountServices";

const useSupplierAccounts = (page = 1, limit = 10, searchQuery = "") => {
  return useQuery(["supplierAccounts", page, limit, searchQuery], 
    () => fetchSupplierAccounts(page, limit, searchQuery), 
    {
      staleTime: 1000 * 60 * 5,
      keepPreviousData: true, // Evita parpadeos al cambiar de página
      refetchOnWindowFocus: false,
    }
  );
};

export default useSupplierAccounts;
