import { http } from "../axiosConfig";

export const getBuyerAccountById = async (buyerId) => {
  try {
    const { data } = await http.get(`/api/buyerAccounts/${buyerId}`);
    return data;
  } catch (error) {
    console.error("Error al obtener buyerAccounts:", error);
    throw error;
  }
};
