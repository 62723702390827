import {FC} from 'react'
import { Suppliers } from '../../../../_metronic/layout/components/tables/Suppliers'

const SuppliersTable: FC = () => {
  return (
    <>
      <Suppliers className='mb-5 mb-xl-8' />
    </>
  )
}

export {SuppliersTable}
