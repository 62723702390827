import { useMutation, useQueryClient } from "react-query";
import toast from "react-hot-toast";
import { checkBrowserService } from "../../../app/services/checkBrowserService";

const useCheckBrowser = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ id, accountType }: { id: string; accountType: "buyers" | "suppliers" }) => 
      checkBrowserService(id, accountType),
    {
      onSuccess: (data, { accountType }) => {
        // toast.success("Browser session checked successfully");

        // 🔄 Invalidar y refrescar la lista de cuentas después del chequeo
        queryClient.invalidateQueries(`${accountType}Accounts`);
      },
      onError: (error) => {
        console.error("❌ Error checking browser session:", error);
        // toast.error("Failed to check browser session");
      },
    }
  );
};

export default useCheckBrowser;
