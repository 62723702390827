import { http } from "../axiosConfig";

export const deleteSupplierAccount = async (id) =>  {
  try {
    const url = `/api/supplierAccounts/deleteSupplierAccount/${id}`;
    return await http.delete(url).then((response) => response.data);
  } catch (error) {
    console.error("Error al borrar supplierAccount:", error);
    throw error;
  }
}
