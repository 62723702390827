/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useMemo, useState} from 'react'
import {CreateAppModal} from '../../../partials/modals/create-app-stepper/CreateAppModal'
import useBuyerAccounts from '../../../hooks/useBuyerAccounts'
import {format} from 'date-fns'
import useTransferSessions from '../../../hooks/useTransferSession'
import {BulkUploadCSVModal} from '../../../partials/modals/create-app-stepper/BulkCreateAppModal'
import Swal from 'sweetalert2'
import BuyerTransactionsModal from '../../../partials/modals/create-app-stepper/BuyerTransactionsModal'
import {Card, Form} from 'react-bootstrap'
import useChangeStatus from '../../../hooks/frontendActions/usechangeStatus'
import useResumeAccount from '../../../hooks/frontendActions/useResumeAccount'
import useDeleteBuyerAccount from '../../../hooks/frontendActions/useDeleteBuyerAccount'
import usePauseAccount from '../../../hooks/frontendActions/usePauseAccount'
import useRelogin from '../../../hooks/frontendActions/useRelogin'
import useCheckBrowser from '../../../hooks/frontendActions/useCheckBrowser'
import { getBgClass, getStatusStyle } from '../../../helpers/statusAccountStyles'
import { Link } from 'react-router-dom'
import { KTIcon } from '../../../helpers'
import useSupplierAccounts from '../../../hooks/useSupplierAccounts'
import useHideBuyerAccount from '../../../hooks/frontendActions/useHideBuyerAccount'
import { useAuth } from '../../../../app/modules/auth'
import { EditBuyerAccountModal } from '../../../partials/modals/create-app-stepper/EditBuyerAccountModal'



// import pc from "../media/logos/pc.png";

type Props = {
  className: string
}

const Buyers: React.FC<Props> = ({className}) => {
  const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false)
  const [showBulkCreateAppModal, setShowBulkCreateAppModal] = useState<boolean>(false)
  const [selectedValues, setSelectedValues] = useState<{[key: string]: string}>({})
  const [selectedBuyerForEdit, setSelectedBuyerForEdit] = useState<any>(null)

  const {data: allTransferSessions} = useTransferSessions()
  const [searchQuery, setSearchQuery] = useState<string>('')
  const deleteBuyerAccountMutation = useDeleteBuyerAccount()
  const changeStatusMutation = useChangeStatus()
  const resumeAccountMutation = useResumeAccount()
  const pauseAccountMutation = usePauseAccount()
  const reloginMutation = useRelogin()
  const checkBrowserMutation = useCheckBrowser()
  const hideBuyerAccountMutation = useHideBuyerAccount()
  const { currentUser } = useAuth();

  // 🔥 Estado para manejar el modal
  const [showModal, setShowModal] = useState(false)
  const [selectedBuyerId, setSelectedBuyerId] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  const itemsPerPage = 25 // Cantidad de registros por página
  const {data: supplierData, isLoading: isLoadingSuppliers, isError: isErrorSuppliers} = useSupplierAccounts(currentPage, itemsPerPage, searchQuery)
  const {data, isLoading, isError} = useBuyerAccounts(currentPage, itemsPerPage, searchQuery)

  const openModal = (buyerId) => {
    setSelectedBuyerId(buyerId)
    setShowModal(true)
  }
  const formatValue = (value) => {
	  if (value >= 1000000) {
		return `${(value / 1000000).toFixed(1)} M`; // Formato en millones
	  } else if (value >= 1000) {
		return `${Math.floor(value / 1000)} K`; // Formato en miles
	  } else {
		return value; // Valores menores a mil
	  }
	};

  const totalStockAvailable = useMemo(() => {
    if (!supplierData || !supplierData.data) return 0; // ⚠️ Ajusta si `data.data` es la estructura correcta
  
    return supplierData.data
      .filter(
        (acc) =>
          acc?.supplierAccountStatus === "READY" ||
          acc?.supplierAccountStatus === "TRANSFERING..."
      )
      .reduce(
        (sum, acc) =>
          sum + ((acc?.supplierOrder || 0) - (acc?.supplierAccountDeliveredCoins || 0)),
        0
      );
  }, [supplierData]); // ⚠️ Solo se recalcula cuando `data` cambia

  const handleChangeToCompleted = (buyerId: string) => {
    changeStatusMutation.mutate({id: buyerId, accountType: 'buyers'})
  }
  const handlePause = (buyerId: string) => {
    pauseAccountMutation.mutate({id: buyerId, accountType: 'buyers'})
  }

  const handleRelogin = (email: string, accountType: 'buyers') => {
    reloginMutation.mutate({email, accountType: 'buyers'})
  }

  const handleCheckBrowser = (buyerId: string) => {
    checkBrowserMutation.mutate({id: buyerId, accountType: 'buyers'})
  };

  const processedData = useMemo(() => {
    if (!data || !data.data) return []
    return data.data.filter(
      (
        item // ✅ data.data es un array
      ) =>
        item.buyerEmail.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.id.toString().includes(searchQuery)
    )
  }, [data, searchQuery])

  const getTransferSessionForAccount = (accountId) => {
    const accountSessions = allTransferSessions?.filter(
      (session) => session.buyerAccountId === accountId
    )

    if (!accountSessions || accountSessions.length === 0) {
      return null // No hay sesiones para este buyerAccountId
    }

    // Ordenar por updatedAt descendente y devolver la más reciente
    return accountSessions.sort(
      (a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
    )[0]
  }

  console.log(currentUser)
  const handleDeleteBuyer = (buyerAccountId: string) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Please confirm that you want to delete this account.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#F1416C', // Metronic Danger Red
      cancelButtonColor: '#7239EA', // Metronic Info Purple
      confirmButtonText: 'Yes, delete',
      cancelButtonText: 'Cancel',
    }).then((result) => {
      if (result.isConfirmed) {
        deleteBuyerAccountMutation.mutate(buyerAccountId)
        Swal.fire('Deleted!', 'The account has been successfully deleted.', 'success')

        // ✅ Remover la fila del estado después de la eliminación
        setSelectedValues((prev) => {
          const newValues = {...prev}
          delete newValues[buyerAccountId] // Eliminamos la referencia de la cuenta eliminada
          return newValues
        })
      } else {
        // 🔥 Volver a "Active" solo para la fila donde se canceló el Swal
        setSelectedValues((prev) => ({
          ...prev,
          [buyerAccountId]: 'Active',
        }))
      }
    })
  }

  useEffect(() => {
    console.log('🔄 Renderizando tabla...')
  })

  if (isLoading) return <p>Loading Accounts...</p>
  if (isError) return <p>Error loading accounts</p>

  const handlePageChange = (newPage: number) => {
    if (newPage >= 1 && newPage <= data.totalPages) {
      setCurrentPage(newPage)
    }
  }

  console.log('Buyers Accounts:')
  console.log(data)
  const formatToK = (value) => {
    if (value >= 1000) {
      return `${Math.floor(value / 1000)}K`
    }
    return value?.toLocaleString() // Si es menor a 1000, muestra el número completo.
  }

  const relevantStatuses = ['READY', 'TRANSFERING...', 'MATCHED']
  const count = processedData.filter(account =>
    relevantStatuses.includes(account.buyerAccountStatus)
  ).length

  console.log('Cuentas con status READY, TRANSFERING... o MATCHED:', count)

  return (
    <>
      <div className="row mb-4 g-3">
        {/* TÍTULO */}
        <div className="col-12 col-md-3 d-flex align-items-center">
          <Card className="shadow-sm p-3 w-100 h-100 d-flex align-items-center">
            <div className="d-flex align-items-center w-100 overflow-hidden">
              <h1 className="fctt-orders me-2 flex-shrink-1">Orders Accounts</h1>
              <i className="ki-duotone ki-address-book fs-4x title-color flex-shrink-0">
                <span className="path1"></span>
                <span className="path2"></span>
                <span className="path3"></span>
              </i>
            </div>
          </Card>
        </div>
        {/* COINS STOCK */}
        <div className="col-12 col-md-3">
          <Card className="shadow-sm p-3 h-100 text-center">
            <h5 className="mb-3 cards-titulos">COINS STOCK</h5>
            <span className="fw-bold fs-5 mb-2 d-block">
              <img className="pc-png" src="/media/logos/psn.png" alt="Logo Pc" />
              <img className="xbox-png" src="/media/logos/xbox.png" alt="Logo Console" />
              {formatValue(totalStockAvailable)}
            </span>
            <span className="fw-bold fs-5">
              <img className="pc-png" src="/media/logos/pc.png" alt="Logo PC" />
              0
            </span>
          </Card>
        </div>

        {/* ACTIVE ORDERS */}
        <div className="col-12 col-md-3">
          <Card className="shadow-sm p-3 h-100 text-center">
            <h5 className="mb-3 cards-titulos">ACTIVE ORDERS</h5>
            <span className='fw-bold fs-5 mb-2'>
              <img className="pc-png" src="/media/logos/psn.png" alt="Logo Pc" />
              <img className="xbox-png me-3" src="/media/logos/xbox.png" alt="Logo Console" />
              {count} Acc
            </span>
            <span className='fw-bold fs-5 mb-2'>
            <img className="pc-png" src="/media/logos/pc.png" alt="Logo PC" />
              0 Acc 
            </span>
          </Card>
        </div>

        {/* EXTRA INFO */}
        <div className="col-12 col-md-3">
          <Card className="shadow-sm p-3 h-100 text-center">
          <h5 className="mb-5 cards-titulos">SUCCESS RATE (24hs)</h5>
          <h2 className='mt-2'>97%</h2>
          </Card>
        </div>
      </div>

      
      <div className={`card ${className}`}>
        {/* begin::Header */}
        <div className='card-header border-0 d-flex align-items-center justify-content-between w-100'>
          <div>
            <button
              type='button'
              className='btn btn-sm  btn-primary '
              onClick={() => setShowCreateAppModal(true)}
            >
              Add Order
              {/* <KTIcon iconName='plus-circle' className='fs-2' /> */}
            </button>
            <button
              type='button'
              className='btn btn-sm  btn-info  ms-4'
              onClick={() => setShowBulkCreateAppModal(true)}
            >
              Add Bulk Orders
              {/* <KTIcon iconName='plus-circle' className='fs-2' /> */}
            </button>
          </div>
          <div className='ms-auto' style={{width: '250px'}}>
            <div className='input-group input-group-sm'>
              <span className='input-group-text'>
                <i className='ki-outline ki-magnifier fs-2'></i>{' '}
                {/* 🔍 Ícono de búsqueda de Metronic */}
              </span>
              <Form.Control
                type='text'
                placeholder='Search by ID or Email...'
                className='form-control form-control-sm'
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
          </div>

          <div className='card-toolbar'>
            <div
              className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold w-200px'
              data-kt-menu='true'
            ></div>
          </div>
        </div>

        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table table-bordered align-middle gs-0 gy-3'>
              {/* begin::Table head */}
              <thead
                className='text-nowrap fw text-gray-900 hola'
                style={{backgroundColor: '#0D0E12'}}
              >
                <tr className='text-muted'>
                  <th className='text-center w-100px text-secondary'>Info</th>
                  <th className='text-center min-w-150px text-secondary'>ID</th>
                  <th className='text-center min-w-140px text-secondary'>Buyer</th>
                  <th className='text-center min-w-120px text-secondary'>Coins Start</th>
                  <th className='text-center min-w-120px text-secondary'>Coins Taken</th>
                  <th className='text-center min-w-120px text-secondary'>Last Update</th>
                  <th className='text-center min-w-120px text-secondary'>Status</th>
                  <th className='text-center min-w-120px text-secondary'>Order</th>
                  <th className='text-center min-w-100px text-end text-secondary'>Coins End</th>
                  <th className='text-center min-w-100px text-end text-secondary'>Actions</th>
                  <th className='text-center min-w-100px text-end text-secondary'>Active</th>
                </tr>
              </thead>

              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody className=''>
                {processedData.map((item, index) => {
                  const transferSession = getTransferSessionForAccount(item.id)
                  return (
                    <tr key={index}>
                      
                      <td className='text-center'> {/* Columna Info */}
                        <i
                          className='ki-solid ki-information-2 fs-2 text-primary cursor-pointer'
                          onClick={() => openModal(item.id)}
                          style={{cursor: 'pointer'}}
                        ></i>
                      </td>

                      <td className='text-center'> {/* Columna ID */}
                        <a href='#' className='text-gray-800'>
                          <Link to={`/order-info/${item.Orders[0]?.orderId}`}>
                          {item.id}
                          </Link>
                        </a>
                      </td>
                      <td className='text-center'> {/* Columna Buyer (Email) */}
                        <span className="fs-5 d-block">
                          <a href='#' className='text-gray-800'>
                            {item.buyerAccountPlatform === 'pc' 
                            ? <>
                              <span className="fs-5">
                                <img className="pc-png" src="/media/logos/pc.png" alt="Logo PC" />
                                 {item.buyerEmail.replace(/\d+$/, '')}
                              </span>
                              </>
                            : <>
                            <img className="pc-png" src="/media/logos/psn.png" alt="Logo Pc" />
                            <img className="xbox-png" src="/media/logos/xbox.png" alt="Logo Console" />
                            {item.buyerEmail.replace(/\d+$/, '')}
                            
                            </> 
                            }
                          </a>
                        </span>
                      </td>
                      <td className='text-gray-800 text-center'> {/* Columna Coins Start */}
                        {item.buyerAccountStatus === 'LOGIN ERROR' ||
                          item.buyerAccountStatus === 'ERROR LOGIN' ||
                          item.buyerAccountStatus === 'ERROR LOGIN - BACKUP CODE' ||
                          item.buyerAccountStatus === 'ERROR LOGIN - USER/PASS'
                          ? '-----'
                          : formatToK(item.buyerAccountInitialCoins)
                        }
                      </td>
                      <td className='text-gray-800 text-center'>
                        {' '}
                        <div> {formatToK(transferSession?.totalCoinsTransferred ?? 0)}</div>
                      </td>
                      <td className='text-gray-800 text-center'>
                        {item.updatedAt
                          ? format(new Date(item.updatedAt), 'dd-MMM-yyyy h.mmaaa')
                          : 'Not available'}
                      </td>
                      <td className='text-center text-gray-800'> {/* Columna Status */}
                        <div className='d-flex justify-content-center'>
                          <p
                            className={`px-3 py-1 rounded-2 my-1 ${getBgClass(
                              item.buyerAccountStatus
                            )}`}
                            style={{ ...getStatusStyle(item.buyerAccountStatus) }} // 🔥 Se aplican los estilos aquí
                          >
                            {item.buyerAccountStatus}
                          </p>
                        </div>
                      </td>

                      <td className='text-center text-gray-800'> {/* Columna Order */}
                        {formatToK(item.buyerAccountTakenCoins)} /  {formatToK(item.buyerOrder)} ({((item.buyerAccountTakenCoins / item.buyerOrder) * 100).toFixed(0)}%)
                      </td>

                      <td className='text-center text-gray-800'> {/* Columna Coins End */}
                        {formatToK(item.buyerAccountActualCoins)}
                      </td>
                      
                      <td className='text-center' style={getStatusStyle(item?.buyerAccountStatus)}>  {/* Columna Actions Desplegable */}
                        <div className='d-flex justify-content-center'>
                          <select
                            name='status'
                            className='form-select form-select-sm form-select-white w-125px text-center'
                            value={selectedValues[item.id] ?? 'Active'} // 🔥 Siempre mantiene "Active" si no hay otro valor
                            onChange={(e) => {
                              const newValue = e.target.value
                              // ✅ Actualizar el estado local para reflejar el cambio visualmente
                              setSelectedValues((prev) => ({
                                ...prev,
                                [item.id]: newValue,
                              }))

                              // ✅ Llamar la función correcta según la acción seleccionada
                              switch (newValue) {
                                case 'Edit':
                                  setSelectedBuyerForEdit(item)
                                  break
                                case 'Delete':
                                  handleDeleteBuyer(item.id)
                                  break
                                case 'Completed':
                                  changeStatusMutation.mutate({id: item.id, accountType: 'buyers'})
                                  break
                                case 'Resume':
                                  resumeAccountMutation.mutate({id: item.id, accountType: 'buyers'})
                                  break
                                case 'Pause':
                                  pauseAccountMutation.mutate({id: item.id, accountType: 'buyers'})
                                  break
                                case 'Relogin':
                                  reloginMutation.mutate({
                                    email: item.buyerEmail,
                                    accountType: 'buyers',
                                  })
                                  break
                                case 'CheckBrowser':
                                  checkBrowserMutation.mutate({id: item.id, accountType: 'buyers'})
                                  break
                                case 'Hide':
                                  hideBuyerAccountMutation.mutate(item.id)
                                  break
                                default:
                                  break
                              }
                            }}
                          >
                            <option value='Active'>Active</option>
                            {item.buyerAccountStatus === 'READY' ||
                            item.buyerAccountStatus === 'TRANSFERING...' ||
                            item.buyerAccountStatus === 'MATCHED'
                            ? <>
                                <option value='Edit'>Edit</option>
                                <option value='Completed'>Completed</option>
                                <option value='Hide'>Delete</option>
                                <option value='Pause'>Pause</option>
                              </> 
                            
                            : null
                          }

                          {item.buyerAccountStatus === 'ORDER COMPLETED'
                            ? <>
                              <option value='Hide'>Delete</option>
                            
                            </>
                            : null
                          }
                            {
                              item.buyerAccountStatus === 'BANNED FROM MARKET'||
                              item.buyerAccountStatus === 'BROWSER CLOSED'||
                              item.buyerAccountStatus === 'CONSOLE LOGGED IN'||
                              item.buyerAccountStatus === 'ENABLE TWO FACTOR AUTH'||
                              item.buyerAccountStatus === 'ERROR - NO COINS'||
                              item.buyerAccountStatus === 'ERROR LOGIN'||
                              item.buyerAccountStatus === 'ERROR LOGIN - USER/PASS'||
                              item.buyerAccountStatus === 'ERROR LOGIN - BACKUP CODE'||
                              item.buyerAccountStatus === 'GENERAL ERROR'||
                              item.buyerAccountStatus === 'PLAYER NOT SOLD'||
                              item.buyerAccountStatus === 'PROXY_ERROR'||
                              item.buyerAccountStatus === 'LOGIN ERROR'||
                              item.buyerAccountStatus === 'NEW ACCOUNT, NO CLUB'||
                              item.buyerAccountStatus === 'NEED RELOGIN'||
                              item.buyerAccountStatus === 'SERVERS_SLOW'||
                              item.buyerAccountStatus === 'TM LOCKED. UNLOCK IT!'||
                              item.buyerAccountStatus === 'TM WITHOUT ACCESS. NO +INFO'||
                              item.buyerAccountStatus === 'TRANSFER LIST FULL'||
                              item.buyerAccountStatus === 'WATCH LIST FULL'||
                              item.buyerAccountStatus === 'WRONG PLATFORM'||
                              item.buyerAccountStatus === 'WEBAPP MULTIPLE SESSION LOGGED'
                              ? <>
                                  <option value='Edit'>Edit</option>
                                  <option value='Relogin'>Relogin</option>
                                  <option value='Resume'>Resume</option>
                                  <option value='Hide'>Delete</option>
                                </>
                              : null
                            }
                            {item.buyerAccountStatus === 'PAUSED BY BOT ADMIN'
                              ? <>
                                  <option value='Edit'>Edit</option>
                                  <option value='Resume'>Resume</option>
                                  <option value='Completed'>Completed</option>
                                  <option value='Hide'>Delete</option>
                                </>
                              : null
                            }
                            
                            {currentUser?.role === 'admin' && (
                              <>
                                <option value='Delete'>Delete REAL</option>
                                <option value='CheckBrowser'>Check Browser</option>
                              </>
                            )}
                          </select>
                        </div>
                      </td>
                    </tr>
                  )
                })}
              </tbody>

              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* 🔥 Paginación alineada a la derecha */}
          <div className='d-flex justify-content-end mt-4'>
          <ul className='pagination'>

            {/* Botón Primero */}
            <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
              <button className='page-link' onClick={() => handlePageChange(1)}>
                «
              </button>
            </li>

            {/* Botón Anterior */}
            <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
              <button className='page-link' onClick={() => handlePageChange(currentPage - 1)}>
                <i className='ki-duotone ki-left fs-5'></i>
              </button>
            </li>

            {/* Números de Página (máximo 5 visibles) */}
            {(() => {
              const pageNumbers: JSX.Element[] = [];
              const maxVisiblePages = 5;
              let startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
              let endPage = startPage + maxVisiblePages - 1;

              if (endPage > data.totalPages) {
                endPage = data.totalPages;
                startPage = Math.max(1, endPage - maxVisiblePages + 1);
              }

              if (startPage > 1) {
                pageNumbers.push(
                  <li key="start-ellipsis" className="page-item disabled">
                    <span className="page-link">...</span>
                  </li>
                );
              }

              for (let i = startPage; i <= endPage; i++) {
                pageNumbers.push(
                  <li key={i} className={`page-item ${currentPage === i ? 'active' : ''}`}>
                    <button className="page-link" onClick={() => handlePageChange(i)}>
                      {i}
                    </button>
                  </li>
                );
              }

              if (endPage < data.totalPages) {
                pageNumbers.push(
                  <li key="end-ellipsis" className="page-item disabled">
                    <span className="page-link">...</span>
                  </li>
                );
              }

                return pageNumbers;
            })()}


            {/* Botón Siguiente */}
            <li className={`page-item ${currentPage === data.totalPages ? 'disabled' : ''}`}>
              <button className='page-link' onClick={() => handlePageChange(currentPage + 1)}>
                <i className='ki-duotone ki-right fs-5'></i>
              </button>
            </li>

            {/* Botón Último */}
            <li className={`page-item ${currentPage === data.totalPages ? 'disabled' : ''}`}>
              <button className='page-link' onClick={() => handlePageChange(data.totalPages)}>
                »
              </button>
            </li>

          </ul>
        </div>
        {/* end::Table container */}
        </div>
        {/* begin::Body */}
        <CreateAppModal show={showCreateAppModal} handleClose={() => setShowCreateAppModal(false)} />
        <BulkUploadCSVModal
          show={showBulkCreateAppModal}
          handleClose={() => setShowBulkCreateAppModal(false)}
        />
        {selectedBuyerId && (
          <BuyerTransactionsModal
            show={showModal}
            handleClose={() => setShowModal(false)}
            buyerId={selectedBuyerId}
          />
        )}
        {selectedBuyerForEdit && (
          <EditBuyerAccountModal
            show={true}
            handleClose={() => setSelectedBuyerForEdit(null)}
            account={selectedBuyerForEdit}
          />
        )}
      </div>
    </>
  )
}

export {Buyers}
