import { useMutation, useQueryClient } from "react-query";
import { deleteSupplierAccount } from "../../../app/services/supplierServices/deleteSupplierAccountServices";

const useDeleteSupplierAccount = () => {
  const queryClient = useQueryClient();

  return useMutation(deleteSupplierAccount, {
    onSuccess: () => {
      queryClient.invalidateQueries("supplierAccounts");
    },
  });
};

export default useDeleteSupplierAccount;
