import {FC} from 'react'
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom'
import {PrivateRoutes} from './PrivateRoutes'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {Logout, AuthPage, useAuth} from '../modules/auth'
import {App} from '../App'
import { Toaster } from 'react-hot-toast'
import OrderInfo from '../pages/OrderInfo'

const BASE_NAME = process.env.REACT_APP_PUBLIC_URL || '/'

const AppRoutes: FC = () => {
  const {currentUser} = useAuth() // Verificamos si el usuario está autenticado

  return (
    <BrowserRouter basename="/">
      <Toaster position="bottom-center" reverseOrder={true} />
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
          
          {/* Si el usuario está autenticado, usar rutas privadas */}
          {currentUser ? (
            <>
              <Route path='/*' element={<PrivateRoutes />} />
              <Route index element={<Navigate to='/tables' replace />} />
              <Route index element={<Navigate to='/suppliers' replace />} />
              <Route path="/order-info/:orderId" element={<OrderInfo />} />

            </>
          ) : (
            <>
              <Route path='auth/*' element={<AuthPage />} />
              {/* 🚀 Redirigir cualquier otra ruta a `/auth/login` si no está autenticado */}
              <Route path='*' element={<Navigate to='/auth/login' replace />} />
              <Route path='/' element={<Navigate to='/auth/login' replace />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export {AppRoutes}
