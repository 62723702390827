import { useQuery } from "react-query";
import { fetchBuyerAccounts } from "../../app/services/buyerServices/buyerAccountServices";

const useBuyerAccounts = (page = 1, limit = 10, searchQuery = "") => {
  return useQuery(["buyerAccounts", page, limit, searchQuery], 
    () => fetchBuyerAccounts(page, limit, searchQuery), 
    {
      staleTime: 1000 * 60 * 5, // Mantiene los datos en caché por 5 minutos
      keepPreviousData: true, // Evita parpadeos al cambiar de página
      refetchOnWindowFocus: false, // No recarga los datos al cambiar de pestaña
    }
  );
};

export default useBuyerAccounts;
