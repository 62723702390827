import React, {FC} from 'react'
import { Buyers } from '../../../../_metronic/layout/components/tables/Buyers'


const Tables: FC = () => {
  return (
    <>
      <Buyers className='mb-5 mb-xl-8' />
    </>
  )
}

export {Tables}
