import { useState } from "react";
import * as Yup from "yup";
import clsx from "clsx";
import { useFormik } from "formik";
import { useAuth } from "../core/Auth";
import { login } from "../core/_requests";
import { useNavigate } from "react-router-dom";

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Formato de email incorrecto")
    .required("El email es obligatorio"),
  password: Yup.string().required("La contraseña es obligatoria"),
});

export function Login() {
  const [loading, setLoading] = useState(false);
  const { saveAuth } = useAuth();
  const navigate = useNavigate(); // 🔥 Hook de React Router

  const formik = useFormik({
    initialValues: { email: "", password: "" },
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      try {
        // 🔥 Llamamos al backend con el email y password
        const { token } = await login(values.email, values.password);
        saveAuth(token); // ✅ Guardamos token

        navigate("/tables", { replace: true }); // 🔥 Redirige sin volver atrás
      } catch (error) {
        setStatus("Las credenciales son incorrectas");
        setSubmitting(false);
        setLoading(false);
      }
    },
  });

  return (
    <form className="form w-100" onSubmit={formik.handleSubmit} noValidate>
      <div className="text-center mb-11">
        <h1 className="text-dark fw-bolder mb-3">FCTT - Sign In</h1>
      </div>

      {formik.status && (
        <div className="mb-lg-15 alert alert-danger">
          <div className="alert-text font-weight-bold">{formik.status}</div>
        </div>
      )}

      <div className="fv-row mb-8">
        <label className="form-label fs-6 fw-bolder text-dark">Email</label>
        <input
          placeholder="Email"
          {...formik.getFieldProps("email")}
          className={clsx("form-control bg-transparent", {
            "is-invalid": formik.touched.email && formik.errors.email,
            "is-valid": formik.touched.email && !formik.errors.email,
          })}
          type="email"
          name="email"
          autoComplete="off"
        />
      </div>

      <div className="fv-row mb-3">
        <label className="form-label fw-bolder text-dark fs-6 mb-0">Password</label>
        <input
          type="password"
          autoComplete="off"
          {...formik.getFieldProps("password")}
          className={clsx("form-control bg-transparent", {
            "is-invalid": formik.touched.password && formik.errors.password,
            "is-valid": formik.touched.password && !formik.errors.password,
          })}
        />
      </div>

      <div className="d-grid mb-10 mt-15">
        <button
          type="submit"
          className="btn btn-primary"
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading ? "Ingresar" : "Cargando..."}
        </button>
      </div>
    </form>
  );
}
