import {useState} from 'react'
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import {Formik, Field, Form, ErrorMessage} from 'formik'
import * as Yup from 'yup'
import {KTIcon} from '../../../helpers'
import {useMutation, useQueryClient} from 'react-query'
import Swal from 'sweetalert2'
import { reloginInEditBuyer } from '../../../../app/services/buyerServices/reloginInEditBuyer'
import { updateAccount } from '../../../../app/services/updateAccountServices'

type Props = {
  show: boolean
  handleClose: () => void
  account: any
}

const modalsRoot = document.getElementById('root-modals') || document.body

const EditBuyerAccountModal = ({show, handleClose, account}: Props) => {
  const queryClient = useQueryClient()

  const updateMutation = useMutation(updateAccount, {
    onSuccess: () => {
      queryClient.invalidateQueries('buyerAccounts')
    },
  })
  
  const reloginMutation = useMutation(reloginInEditBuyer, {
    onSuccess: () => {
      queryClient.invalidateQueries('buyerAccounts')
    },
  })

  const validationSchema = Yup.object().shape({
    buyerEmail: Yup.string().email('Invalid email').required('Email is required'),
    buyerPassword: Yup.string().min(4, 'Minimum 4 characters').required('Password is required'),
    buyerBackupCode: Yup.string().length(8, 'Must be 8 characters'),
    buyerOrder: Yup.number().positive('Must be positive').required('Order is required'),
  })

  const statusCanRelogin = [
    'ERROR LOGIN - BACKUP CODE',
    'ERROR LOGIN - USER/PASS',
    'CONSOLE LOGGED IN',
    'BROWSER CLOSED',
    'ORDER COMPLETED',
    'UNASSIGNED PLAYERS ERROR',
    'WEBAPP MULTIPLE SESSION LOGGED',
    'WATCH LIST FULL',
    'TRANSFER LIST FULL',
    'TM LOCKED. UNLOCK IT!',
    'WRONG PLATFORM',
    'GENERAL ERROR',
    'LOGGED - LOCKED TM',
    'LOGIN_UNAVAILABLE',
  ]

  return createPortal(
    <Modal
      show={show}
      onHide={handleClose}
      dialogClassName='modal-dialog modal-dialog-centered mw-600px'
      backdrop={true}
    >
      <div className='modal-header'>
        <h2>Edit Buyer Account</h2>
        <div className='btn btn-sm btn-icon btn-active-color-info' onClick={handleClose}>
          <KTIcon className='fs-1' iconName='cross' />
        </div>
      </div>

      <div className='modal-body py-lg-10 px-lg-10'>
        <Formik
          initialValues={{
            buyerEmail: account.buyerEmail || '',
            buyerPassword: account.buyerPassword || '',
            buyerBackupCode: account.buyerBackupCode || '',
            buyerOrder: account.buyerOrder || '',
            buyerAccountPlatform: account.buyerAccountPlatform || '',
          }}
          validationSchema={validationSchema}
          onSubmit={(values, {setSubmitting}) => {
            const formattedValues = {
              ...values,
              buyerEmail: values.buyerEmail,
              accountType: 'buyers',
            }
          
            if (statusCanRelogin.includes(account.buyerAccountStatus)) {
              reloginMutation.mutate(formattedValues)
            } else {
              updateMutation.mutate({
                id: account.id,
                accountType: 'buyers',
                values: formattedValues,
              })
            }
          
            handleClose()
          
            Swal.fire({
              icon: 'success',
              title: 'Updated',
              text: statusCanRelogin.includes(account.buyerAccountStatus)
                ? 'Relogin triggered and account updated.'
                : 'Account updated.',
              timer: 1500,
              showConfirmButton: false,
              background: '#1e1e1e',
              color: '#ffffff',
            })
          
            setSubmitting(false)
          }}
          
        >
          {({errors, touched}) => (
            <Form noValidate>
              {/* Email */}
              <div className='mb-4'>
                <Field
                  type='email'
                  name='buyerEmail'
                  placeholder='Email'
                  className={`form-control ${
                    errors.buyerEmail && touched.buyerEmail ? 'is-invalid' : ''
                  }`}
                />
                <ErrorMessage name='buyerEmail' component='div' className='text-danger' />
              </div>

              {/* Password */}
              <div className='mb-4'>
                <Field
                  type='text'
                  name='buyerPassword'
                  placeholder='Password'
                  className={`form-control ${
                    errors.buyerPassword && touched.buyerPassword ? 'is-invalid' : ''
                  }`}
                />
                <ErrorMessage name='buyerPassword' component='div' className='text-danger' />
              </div>

              {/* Backup Code */}
              <div className='mb-4'>
                <Field
                  type='text'
                  name='buyerBackupCode'
                  placeholder='Backup Code'
                  className={`form-control ${
                    errors.buyerBackupCode && touched.buyerBackupCode ? 'is-invalid' : ''
                  }`}
                />
                <ErrorMessage name='buyerBackupCode' component='div' className='text-danger' />
              </div>

              {/* Platform */}
              <div className='mb-4'>
                <Field
                  as='select'
                  name='buyerAccountPlatform'
                  className='form-select'
                >
                  <option value=''>Select Platform</option>
                  <option value='CONSOLE'>CONSOLE</option>
                  <option value='PC'>PC</option>
                </Field>
              </div>

              {/* Order */}
              <div className='mb-4'>
                <Field
                  type='number'
                  name='buyerOrder'
                  placeholder='Order Amount'
                  className={`form-control ${
                    errors.buyerOrder && touched.buyerOrder ? 'is-invalid' : ''
                  }`}
                />
                <ErrorMessage name='buyerOrder' component='div' className='text-danger' />
              </div>

              {/* Submit */}
              <div className='text-center mt-5'>
              <button type='submit' className='btn btn-primary'>
                {statusCanRelogin.includes(account.buyerAccountStatus)
                  ? 'Save & Relogin'
                  : 'Update Account'}
              </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>,
    modalsRoot
  )
}

export { EditBuyerAccountModal }
