import { useMutation, useQueryClient } from "react-query";
import toast from "react-hot-toast";
import { pauseAccount } from "../../../app/services/pauseAccountServices";

const usePauseAccount = () => {
  const queryClient = useQueryClient();

 return useMutation(
    ({ id, accountType }: { id: string; accountType: "buyers" | "suppliers" }) =>
      pauseAccount(id, accountType),
    {
      onSuccess: () => {
        // toast.success("Status changed to PAUSED"); // Notificación de éxito

        // 🔄 Invalidar y refrescar la lista de cuentas después del cambio
        queryClient.invalidateQueries("buyerAccounts");
        queryClient.invalidateQueries("supplierAccounts");
      },
      onError: (error) => {
        console.error("❌ Error changing status:", error);
        // toast.error("Failed to change status");
      },
    }
  );
};

export default usePauseAccount;
