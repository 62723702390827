import { http } from "../axiosConfig";

export const fetchSupplierAccounts = async (page = 1, limit = 10, search = "") => {
  try {
    const { data } = await http.get(`/api/supplierAccounts?page=${page}&limit=${limit}&search=${search}`);
    return data;
  } catch (error) {
    console.error("Error al obtener supplierAccounts:", error);
    throw error;
  }
};
