import { http } from "./axiosConfig";

export const updateAccount = async ({
  id,
  accountType,
  values,
}: {
  id: number;
  accountType: "buyers" | "suppliers";
  values: any;
}) => {
  try {
    const url = `/api/actions/updateAccount/${accountType}/${id}`;
    const response = await http.put(url, values);
    return response.data;
  } catch (error) {
    console.error("Error al actualizar la cuenta:", error);
    throw error;
  }
};
