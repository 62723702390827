import axios from "axios";

const API_URL = "https://fctransfertool.com";
// const API_URL = process.env.REACT_APP_API_URL || "http://localhost:3000";

// 🔥 Función para hacer login en tu backend
export const login = async (email: string, password: string) => {
  const { data } = await axios.post(`${API_URL}/api/users/login`, { email, password });

  // Guardamos el token en localStorage
  localStorage.setItem("authToken", data.token);

  return data;
};

// 🔥 Función para obtener el usuario actual por token
export const getUserByToken = async () => {
  const token = localStorage.getItem("authToken");

  if (!token) {
    throw new Error("No token available"); // No intentamos hacer la petición sin un token
  }

  const { data } = await axios.get(`${API_URL}/api/users/getUserProfile`, { // 🔥 Ruta corregida
    headers: { Authorization: `Bearer ${token}` },
  });

  return data;
};

