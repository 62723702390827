import {useState} from 'react'
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import {Formik, Field, Form, ErrorMessage} from 'formik'
import * as Yup from 'yup'
import {KTIcon} from '../../../helpers'
import {useMutation, useQueryClient} from 'react-query'
import Swal from 'sweetalert2'
import {updateAccount} from '../../../../app/services/updateAccountServices'
import { reloginInEditSuppliers } from '../../../../app/services/supplierServices/reloginInEditSuppliers'

type Props = {
  show: boolean
  handleClose: () => void
  account: any
}

const modalsRoot = document.getElementById('root-modals') || document.body

const EditSupplierAccountModal = ({show, handleClose, account}: Props) => {
  const queryClient = useQueryClient()

  const updateMutation = useMutation(updateAccount, {
    onSuccess: () => {
      queryClient.invalidateQueries('supplierAccounts')
    },
  })

  const reloginMutation = useMutation(reloginInEditSuppliers, {
    onSuccess: () => {
      queryClient.invalidateQueries('supplierAccounts')
    },
  })

  const validationSchema = Yup.object().shape({
    supplierEmail: Yup.string().email('Invalid email').required('Email is required'),
    supplierPassword: Yup.string().min(4, 'Minimum 4 characters').required('Password is required'),
    supplierBackupCode1: Yup.string().length(8, 'Must be 8 characters'),
    supplierOrder: Yup.number().positive('Must be positive').required('Order is required'),
  })

  const statusCanRelogin = [
    'ERROR LOGIN - BACKUP CODE',
    'ERROR LOGIN - USER/PASS',
    'CONSOLE LOGGED IN',
    'BROWSER CLOSED',
    'ORDER COMPLETED',
    'UNASSIGNED PLAYERS ERROR',
    'WEBAPP MULTIPLE SESSION LOGGED',
    'WATCH LIST FULL',
    'TRANSFER LIST FULL',
    'TM LOCKED. UNLOCK IT!',
    'WRONG PLATFORM',
    'GENERAL ERROR',
    'LOGGED - LOCKED TM',
    'LOGIN_UNAVAILABLE',
  ]

  return createPortal(
    <Modal
      show={show}
      onHide={handleClose}
      dialogClassName='modal-dialog modal-dialog-centered mw-600px'
      backdrop={true}
    >
      <div className='modal-header'>
        <h2>Edit Supplier Account</h2>
        <div className='btn btn-sm btn-icon btn-active-color-info' onClick={handleClose}>
          <KTIcon className='fs-1' iconName='cross' />
        </div>
      </div>

      <div className='modal-body py-lg-10 px-lg-10'>
        <Formik
          initialValues={{
            supplierEmail: account.supplierEmail || '',
            supplierPassword: account.supplierPassword || '',
            supplierBackupCode1: account.supplierBackupCode1 || '',
            supplierOrder: account.supplierOrder || '',
            supplierAccountPlatform: account.supplierAccountPlatform || '',
          }}
          validationSchema={validationSchema}
          onSubmit={(values, {setSubmitting}) => {
            const formattedValues = {
              ...values,
              accountType: 'suppliers',
              supplierEmail: values.supplierEmail,
            }

            if (statusCanRelogin.includes(account.supplierAccountStatus)) {
              reloginMutation.mutate(formattedValues)
            } else {
              updateMutation.mutate({
                id: account.id,
                accountType: 'suppliers',
                values: formattedValues,
              })
            }

            handleClose()

            Swal.fire({
              icon: 'success',
              title: 'Updated',
              text: statusCanRelogin.includes(account.supplierAccountStatus)
                ? 'Relogin triggered and account updated.'
                : 'Account updated.',
              timer: 1500,
              showConfirmButton: false,
              background: '#1e1e1e',
              color: '#ffffff',
            })

            setSubmitting(false)
          }}
        >
          {({errors, touched}) => (
            <Form noValidate>
              {/* Email */}
              <div className='mb-4'>
                <Field
                  type='email'
                  name='supplierEmail'
                  placeholder='Email'
                  className={`form-control ${
                    errors.supplierEmail && touched.supplierEmail ? 'is-invalid' : ''
                  }`}
                />
                <ErrorMessage name='supplierEmail' component='div' className='text-danger' />
              </div>

              {/* Password */}
              <div className='mb-4'>
                <Field
                  type='text'
                  name='supplierPassword'
                  placeholder='Password'
                  className={`form-control ${
                    errors.supplierPassword && touched.supplierPassword ? 'is-invalid' : ''
                  }`}
                />
                <ErrorMessage name='supplierPassword' component='div' className='text-danger' />
              </div>

              {/* Backup Code 1 */}
              <div className='mb-4'>
                <Field
                  type='text'
                  name='supplierBackupCode1'
                  placeholder='Backup Code 1'
                  className={`form-control ${
                    errors.supplierBackupCode1 && touched.supplierBackupCode1 ? 'is-invalid' : ''
                  }`}
                />
                <ErrorMessage name='supplierBackupCode1' component='div' className='text-danger' />
              </div>

              {/* Platform */}
              <div className='mb-4'>
                <Field as='select' name='supplierAccountPlatform' className='form-select'>
                  <option value=''>Select Platform</option>
                  <option value='CONSOLE'>CONSOLE</option>
                  <option value='PC'>PC</option>
                </Field>
              </div>

              {/* Order */}
              <div className='mb-4'>
                <Field
                  type='number'
                  name='supplierOrder'
                  placeholder='Order Amount'
                  className={`form-control ${
                    errors.supplierOrder && touched.supplierOrder ? 'is-invalid' : ''
                  }`}
                />
                <ErrorMessage name='supplierOrder' component='div' className='text-danger' />
              </div>

              {/* Submit */}
              <div className='text-center mt-5'>
                <button type='submit' className='btn btn-primary'>
                  {statusCanRelogin.includes(account.supplierAccountStatus)
                    ? 'Save & Relogin'
                    : 'Update Account'}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>,
    modalsRoot
  )
}

export {EditSupplierAccountModal}
