/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useMemo, useState} from 'react'
import {format} from 'date-fns'
import useTransferSessions from '../../../hooks/useTransferSession'
import {CreateAppModalSupplier} from '../../../partials/modals/create-app-stepper/CreateAppModalSupplier'
import {BulkUploadCSVModalSupplier} from '../../../partials/modals/create-app-stepper/BulkCreateAppModalSupplier'
import useSupplierAccounts from '../../../hooks/useSupplierAccounts'
import Swal from 'sweetalert2'
import {Card, Form} from 'react-bootstrap'
import useChangeStatus from '../../../hooks/frontendActions/usechangeStatus'
import useResumeAccount from '../../../hooks/frontendActions/useResumeAccount'
import useDeleteSupplierAccount from '../../../hooks/frontendActions/useDeleteSupplierAccount'
import usePauseAccount from '../../../hooks/frontendActions/usePauseAccount'
import useRelogin from '../../../hooks/frontendActions/useRelogin'
import useCheckBrowser from '../../../hooks/frontendActions/useCheckBrowser'
import { getBgClass, getStatusStyle } from '../../../helpers/statusAccountStyles'
import useHideSupplierAccount from '../../../hooks/frontendActions/useHideSupplierAccount'
import { useAuth } from '../../../../app/modules/auth'
import { EditSupplierAccountModal } from '../../../partials/modals/create-app-stepper/EditSupplierAccountModal'



type Props = {
  className: string
}

const Suppliers: React.FC<Props> = ({className}) => {
  const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false)
  const [showBulkCreateAppModal, setShowBulkCreateAppModal] = useState<boolean>(false)
  const [selectedValues, setSelectedValues] = useState<{[key: string]: string}>({})
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [currentPage, setCurrentPage] = useState(1)
  const itemsPerPage = 25 // Cantidad de registros por página
  const {data, isLoading, isError} = useSupplierAccounts(currentPage, itemsPerPage, searchQuery)
  const {data: allTransferSessions} = useTransferSessions()
  const resumeAccountMutation = useResumeAccount()
  const pauseAccountMutation = usePauseAccount()
  const reloginMutation = useRelogin()
  const checkBrowserMutation = useCheckBrowser()
  const deleteSupplierAccountMutation = useDeleteSupplierAccount()
  const hideSupplierAccountMutation = useHideSupplierAccount()
  const { currentUser } = useAuth();
  const [selectedSupplierForEdit, setSelectedSupplierForEdit] = useState<any>(null)
  

  const getTransferSessionForAccount = (accountId) => {
    const accountSessions = allTransferSessions?.filter(
      (session) => session.buyerAccountId === accountId
    )

    if (!accountSessions || accountSessions.length === 0) {
      return null // No hay sesiones para este buyerAccountId
    }

    // Ordenar por updatedAt descendente y devolver la más reciente
    return accountSessions.sort(
      (a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
    )[0]
  }
  const changeStatusMutation = useChangeStatus()

  const handleChangeToCompleted = (supplierId: string) => {
    changeStatusMutation.mutate({id: supplierId, accountType: 'suppliers'})
  }
  const handlePause = (supplierId: string) => {
    pauseAccountMutation.mutate({id: supplierId, accountType: 'suppliers'})
  }

  const handleRelogin = (supplierEmail: string, accountType: string) => {
    reloginMutation.mutate({ email: supplierEmail, accountType: "suppliers" });
  };

  const handleCheckBrowser = (supplierId: string) => {
    checkBrowserMutation.mutate({id: supplierId, accountType: 'suppliers'})
  };

  const formatValue = (value) => {
	  if (value >= 1000000) {
		return `${(value / 1000000).toFixed(1)} M`; // Formato en millones
	  } else if (value >= 1000) {
		return `${Math.floor(value / 1000)} K`; // Formato en miles
	  } else {
		return value; // Valores menores a mil
	  }
	};
  
  console.log(data)



  // ⏳ Evita renders innecesarios con useMemo()
  // const processedData = useMemo(() => data || [], [data])
  const processedData = useMemo(() => {
    if (!data || !data.data) return []
    return data.data.filter(
      (item) =>
        item.supplierEmail.toLowerCase().includes(searchQuery.toLowerCase()) || // Busca en email
        item.id.toString().includes(searchQuery) // Busca en ID
    )
  }, [data, searchQuery])


  const handleDeleteSupplier = (supplierAccountId: string) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Please confirm that you want to delete this account.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#F1416C', // Metronic Danger Red
      cancelButtonColor: '#7239EA', // Metronic Info Purple
      confirmButtonText: 'Yes, delete',
      cancelButtonText: 'Cancel',
    }).then((result) => {
      if (result.isConfirmed) {
        deleteSupplierAccountMutation.mutate(supplierAccountId)
        Swal.fire('Deleted!', 'The account has been successfully deleted.', 'success')

        // ✅ Remover la fila del estado después de la eliminación
        setSelectedValues((prev) => {
          const newValues = {...prev}
          delete newValues[supplierAccountId] // Eliminamos la referencia de la cuenta eliminada
          return newValues
        })
      } else {
        // 🔥 Volver a "Active" solo para la fila donde se canceló el Swal
        setSelectedValues((prev) => ({
          ...prev,
          [supplierAccountId]: 'Active',
        }))
      }
    })
  }

  useEffect(() => {
    console.log('🔄 Renderizando tabla...')
  })
  const totalStockAvailable = useMemo(() => {
    if (!data || !data.data) return 0; // ⚠️ Ajusta si `data.data` es la estructura correcta
  
    return data.data
      .filter(
        (acc) =>
          acc?.supplierAccountStatus === "READY" ||
          acc?.supplierAccountStatus === "TRANSFERING..."
      )
      .reduce(
        (sum, acc) =>
          sum + ((acc?.supplierOrder || 0) - (acc?.supplierAccountDeliveredCoins || 0)),
        0
      );
  }, [data]); // ⚠️ Solo se recalcula cuando `data` cambia
  

  if (isLoading) return <p>Loading Accounts...</p>
  if (isError) return <p>Error loading accounts</p>

  const handlePageChange = (newPage: number) => {
    if (newPage >= 1 && newPage <= data.totalPages) {
      setCurrentPage(newPage)
    }
  }



  const formatToK = (value) => {
    if (value >= 1000) {
      return `${Math.floor(value / 1000)}K`
    }
    return value?.toLocaleString() // Si es menor a 1000, muestra el número completo.
  }

  
  const relevantStatuses = ['READY', 'TRANSFERING...', 'MATCHED']
  const count = processedData.filter(account =>
    relevantStatuses.includes(account.supplierAccountStatus)
  ).length

  console.log('Cuentas Suppliers con status READY, TRANSFERING... o MATCHED:', count)

  return (
    <>
    <div className="row mb-4 text-center">

      <div className="col-12 col-md-3 d-flex align-items-center">
       <Card className="shadow-sm p-3 w-100 h-100 d-flex align-items-center">
          <div className="d-flex align-items-center w-100 overflow-hidden">
          <h1 className="fctt-suppliers me-2 flex-shrink-1">Suppliers Accounts</h1>
          <i className="ki-duotone ki-user fs-3x ms-1 mt-5">
            <span className="path1 title-color-suppliers"></span>
            <span className="path2 title-color-suppliers"></span>
            <span className="path3 title-color-suppliers"></span>
          </i>
          </div>
        </Card>
      </div>
      <div className="col-12 col-md-3">
          <Card className="shadow-sm p-3 h-100 text-center">
            <h5 className="mb-3 cards-titulos">COINS STOCK</h5>
            <span className="fw-bold fs-5 mb-2 d-block">
              <img className="pc-png" src="/media/logos/psn.png" alt="Logo Pc" />
              <img className="xbox-png" src="/media/logos/xbox.png" alt="Logo Console" />
              {formatValue(totalStockAvailable)}
            </span>
            <span className="fw-bold fs-5">
              <img className="pc-png" src="/media/logos/pc.png" alt="Logo PC" />
              0
            </span>
          </Card>
        </div>

        {/* ACTIVE ORDERS */}
        <div className="col-12 col-md-3">
          <Card className="shadow-sm p-3 h-100 text-center">
            <h5 className="mb-3 cards-titulos">ACTIVE SUPPLIERS</h5>
            <span className='fw-bold fs-5 mb-2'>
              <img className="pc-png" src="/media/logos/psn.png" alt="Logo Pc" />
              <img className="xbox-png me-3" src="/media/logos/xbox.png" alt="Logo Console" />
              {count} Acc
            </span>
            <span className='fw-bold fs-5 mb-2'>
            <img className="pc-png" src="/media/logos/pc.png" alt="Logo PC" />
              0 Acc
            </span>
          </Card>
        </div>

        {/* EXTRA INFO */}
        <div className="col-12 col-md-3">
          <Card className="shadow-sm p-3 h-100 text-center">
          <h5 className="mb-5 cards-titulos">SUCCESS RATE (24hs)</h5>
          <h2 className='mt-2'>97%</h2>
          </Card>
        </div>
      </div>
   
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 d-flex align-items-center justify-content-between w-100'>
        <div>
          <button
            type='button'
            className='btn btn-sm  btn-warning text-black'
            onClick={() => setShowCreateAppModal(true)}
          >
            Add Supplier
            {/* <KTIcon iconName='plus-circle' className='fs-2' /> */}
          </button>
          <button
            type='button'
            className='btn btn-sm  btn-info ms-4'
            onClick={() => setShowBulkCreateAppModal(true)}
          >
            Add Bulk Suppliers
            {/* <KTIcon iconName='plus-circle' className='fs-2' /> */}
          </button>
        </div>
        <div className='ms-auto' style={{width: '250px'}}>
          <div className='input-group input-group-sm'>
            <span className='input-group-text'>
              <i className='ki-outline ki-magnifier fs-2'></i>{' '}
              {/* 🔍 Ícono de búsqueda de Metronic */}
            </span>
            <Form.Control
              type='text'
              placeholder='Search by ID or Email...'
              className='form-control form-control-sm'
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
        </div>
        <div className='card-toolbar'>
          <div
            className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold w-200px'
            data-kt-menu='true'
          ></div>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-bordered align-middle gs-0 gy-3'>
            {/* begin::Table head */}
            <thead
              className='text-nowrap fw text-gray-900 hola'
              style={{backgroundColor: '#0D0E12'}}
            >
              <tr className='text-muted'>
                <th className='text-center w-100px text-secondary'>Info</th>
                <th className='text-center min-w-150px text-secondary'>ID</th>
                <th className='text-center min-w-140px text-secondary'>Supplier</th>
                <th className='text-center min-w-120px text-secondary'>Coins Start</th>
                <th className='text-center min-w-120px text-secondary'>Coins Actual</th>
                <th className='text-center min-w-120px text-secondary'>Last Update</th>
                <th className='text-center min-w-120px text-secondary'>Coins Delivered</th>
                <th className='text-center min-w-100px text-secondary'>Status</th>
                <th className='text-center min-w-120px text-secondary'>Order</th>
                <th className='text-center min-w-100px text-secondary'>Actions</th>
                <th className='text-center min-w-100px text-secondary'>Active</th>
              </tr>
            </thead>

            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody className=''>
              {processedData.map((item, index) => {
                const transferSession = getTransferSessionForAccount(item.id)
                return (
                  <tr key={index}>
                    <td className='text-center'>
                      {/* <a href='#' className='text-dark'><span></span></a> */}
                      <i className='ki-solid ki-information-2 fs-2'></i>
                    </td>

                    <td className='text-center'>
                      <a href='#' className='text-gray-800'>
                        {item.id}
                      </a>
                    </td>
                    <td className='text-center'>
                      <a href='#' className='text-gray-800'>
                       {item.supplierEmail.replace(/\d+$/, '')}
                      </a>
                    </td>
                    <td className='text-center'>
                      {item.supplierAccountStatus === 'LOGIN ERROR' ||
                      item.supplierAccountStatus === 'ERROR LOGIN' ||
                      item.supplierAccountStatus === 'ERROR LOGIN - BACKUP CODE' ||
                      item.supplierAccountStatus === 'ERROR LOGIN - USER/PASS' ? (
                        '-' // ✅ Solo muestra texto en lugar de un <a>
                      ) : (
                        <a href='#' className='text-center text-gray-800'>
                          {formatToK(item.supplierAccountInitialCoins)}
                        </a>
                      )}
                    </td>
                    <td className='text-center text-gray-800'>
                      {' '}
                      <div> {formatToK(item.supplierAccountActualCoins)}</div>
                    </td>
                    <td className='text-gray-800 text-center'>
                      {item.updatedAt
                        ? format(new Date(item.updatedAt), 'dd-MMM-yyyy h.mmaaa')
                        : 'Not available'}
                    </td>
                    <td className='text-center text-gray-800'>
                      <div>{formatToK(item.supplierAccountDeliveredCoins)} /{' '}
                      {formatToK(item.supplierOrder)} (
                      {((item.supplierAccountDeliveredCoins / item.supplierOrder) * 100).toFixed(2)}%)</div>
                    </td>
                    <td className='text-center text-gray-800'>
                      <div className='d-flex justify-content-center'>
                        <p
                          className={`px-3 py-1 rounded-2 my-1 ${getBgClass(
                            item.supplierAccountStatus
                          )}`}
                          style={{...getStatusStyle(item.supplierAccountStatus)}} // 🔥 Se aplican los estilos aquí
                        >
                          {item.supplierAccountStatus}
                        </p>
                      </div>
                    </td>

                    <td className='text-center text-gray-800'>
                     {formatToK(item.supplierOrder)}
                    </td>
                    <td className='text-center'>
                      <div className='d-flex justify-content-center text-gray-800'>
                        <select
                          name='status'
                          className='form-select form-select-sm form-select-white w-125px text-center'
                          value={selectedValues[item.id] ?? 'Active'} // 🔥 Siempre mantiene "Active" si no hay otro valor
                          onChange={(e) => {
                            const newValue = e.target.value

                            // ✅ Actualizar el estado local para reflejar el cambio visualmente
                            setSelectedValues((prev) => ({
                              ...prev,
                              [item.id]: newValue,
                            }))

                            // ✅ Llamar la función correcta según la acción seleccionada
                            switch (newValue) {
                              case 'Edit':
                                  setSelectedSupplierForEdit(item)
                                  break
                              case 'Delete':
                                handleDeleteSupplier(item.id)
                                break
                              case 'Completed':
                                changeStatusMutation.mutate({id: item.id, accountType: 'suppliers'})
                                break
                              case 'Resume':
                                resumeAccountMutation.mutate({
                                  id: item.id,
                                  accountType: 'suppliers',
                                })
                                break
                              case 'Pause':
                                pauseAccountMutation.mutate({id: item.id, accountType: 'suppliers'})
                                break
                              case 'Relogin':  
                                handleRelogin(item.supplierEmail, 'suppliers');
                                break;
                              case 'CheckBrowser':
                                checkBrowserMutation.mutate({id: item.id, accountType: 'suppliers'})
                                break
                              case 'Hide':
                                hideSupplierAccountMutation.mutate(item.id)
                                break
                              default:
                                break
                            }
                          }}
                        >
                         <option value='Active'>Active</option>
                            {item.supplierAccountStatus === 'READY' ||
                            item.supplierAccountStatus === 'TRANSFERING...' ||
                            item.supplierAccountStatus === 'MATCHED'
                            ? <>
                                <option value='Edit'>Edit</option>
                                <option value='Completed'>Completed</option>
                                <option value='Hide'>Delete</option>
                                <option value='Pause'>Pause</option>
                            </> 
                            
                            : 'Hola'
                          }

                            {item.supplierAccountStatus === 'ORDER COMPLETED'
                              ? <>
                                <option value='Hide'>Delete</option>
                              
                              </>
                              : 'Hola'
                            }
                            {
                              item.supplierAccountStatus === 'BANNED FROM MARKET'||
                              item.supplierAccountStatus === 'BROWSER CLOSED'||
                              item.supplierAccountStatus === 'CONSOLE LOGGED IN'||
                              item.supplierAccountStatus === 'ENABLE TWO FACTOR AUTH'||
                              item.supplierAccountStatus === 'ERROR - NO COINS'||
                              item.supplierAccountStatus === 'ERROR LOGIN'||
                              item.supplierAccountStatus === 'ERROR LOGIN - USER/PASS'||
                              item.supplierAccountStatus === 'ERROR LOGIN - BACKUP CODE'||
                              item.supplierAccountStatus === 'GENERAL ERROR'||
                              item.supplierAccountStatus === 'PLAYER NOT SOLD'||
                              item.supplierAccountStatus === 'PROXY_ERROR'||
                              item.supplierAccountStatus === 'LOGIN ERROR'||
                              item.supplierAccountStatus === 'NEW ACCOUNT, NO CLUB'||
                              item.supplierAccountStatus === 'NEED RELOGIN'||
                              item.supplierAccountStatus === 'SERVERS_SLOW'||
                              item.supplierAccountStatus === 'TM LOCKED. UNLOCK IT!'||
                              item.supplierAccountStatus === 'TM WITHOUT ACCESS. NO +INFO'||
                              item.supplierAccountStatus === 'TRANSFER LIST FULL'||
                              item.supplierAccountStatus === 'WATCH LIST FULL'||
                              item.supplierAccountStatus === 'WRONG PLATFORM'||
                              item.supplierAccountStatus === 'WEBAPP MULTIPLE SESSION LOGGED'
                              ? <>
                              <option value='Edit'>Edit</option>
                              <option value='Relogin'>Relogin</option>
                              <option value='Resume'>Resume</option>
                              <option value='Hide'>Delete</option>
                              </>
                              : 'Hola'
                            }
                            {item.supplierAccountStatus === 'PAUSED BY BOT ADMIN'
                            ? <>
                                <option value='Edit'>Edit</option>
                                <option value='Resume'>Resume</option>
                                <option value='Completed'>Completed</option>
                                <option value='Hide'>Delete</option>
                              </>
                            : 'Hola'
                          }
                            
                            {currentUser?.role === 'admin' && (
                              <>
                              <option value='Delete'>Delete REAL</option>
                              <option value='CheckBrowser'>Check Browser</option>
                              </>
                            )}
                        </select>
                      </div>
                    </td>
                    <td className='text-center text-gray-800'>
                      <div>{item.isActive === true ? 'YES' : 'NO'}</div>
                    </td>
                  </tr>
                )
              })}
            </tbody>

            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        <div className='d-flex justify-content-end mt-4'>
          <ul className='pagination'>
            {/* Botón Anterior */}
            <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
              <button className='page-link' onClick={() => handlePageChange(1)}>
                «
              </button>
            </li>

           {/* Botón Anterior */}
           <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
              <button className='page-link' onClick={() => handlePageChange(currentPage - 1)}>
                <i className='ki-duotone ki-left fs-5'></i>
              </button>
            </li>

            {/* Números de Página (máximo 5 visibles) */}
            {(() => {
              const pageNumbers: JSX.Element[] = [];
              const maxVisiblePages = 5;
              let startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
              let endPage = startPage + maxVisiblePages - 1;

              if (endPage > data.totalPages) {
                endPage = data.totalPages;
                startPage = Math.max(1, endPage - maxVisiblePages + 1);
              }

              if (startPage > 1) {
                pageNumbers.push(
                  <li key="start-ellipsis" className="page-item disabled">
                    <span className="page-link">...</span>
                  </li>
                );
              }

              for (let i = startPage; i <= endPage; i++) {
                pageNumbers.push(
                  <li key={i} className={`page-item ${currentPage === i ? 'active' : ''}`}>
                    <button className="page-link" onClick={() => handlePageChange(i)}>
                      {i}
                    </button>
                  </li>
                );
              }

              if (endPage < data.totalPages) {
                pageNumbers.push(
                  <li key="end-ellipsis" className="page-item disabled">
                    <span className="page-link">...</span>
                  </li>
                );
              }

                return pageNumbers;
            })()}


            {/* Botón Siguiente */}
            <li className={`page-item ${currentPage === data.totalPages ? 'disabled' : ''}`}>
              <button className='page-link' onClick={() => handlePageChange(currentPage + 1)}>
                <i className='ki-duotone ki-right fs-5'></i>
              </button>
            </li>

            {/* Botón Último */}
            <li className={`page-item ${currentPage === data.totalPages ? 'disabled' : ''}`}>
              <button className='page-link' onClick={() => handlePageChange(data.totalPages)}>
                »
              </button>
            </li>
          </ul>
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
      <CreateAppModalSupplier
        show={showCreateAppModal}
        handleClose={() => setShowCreateAppModal(false)}
      />
      <BulkUploadCSVModalSupplier
        show={showBulkCreateAppModal}
        handleClose={() => setShowBulkCreateAppModal(false)}
      />
      {selectedSupplierForEdit && (
          <EditSupplierAccountModal
            show={true}
            handleClose={() => setSelectedSupplierForEdit(null)}
            account={selectedSupplierForEdit}
          />
        )}
    </div>
    </>
  )
}

export {Suppliers}
