import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Card, Spinner } from "react-bootstrap";
import { useQuery } from "react-query";
import { getOrderById } from "../services/orderService";
import { KTIcon } from "../../_metronic/helpers";
import { getBuyerAccountById } from "../services/buyerServices/buyerAccountByIdService";

const OrderInfo = () => {
  const { orderId } = useParams();
  const [imageError, setImageError] = useState(false);
  const [finalError, setFinalError] = useState(false);

  // ✅ Obtener la orden por ID
  const { data: order, isLoading: orderLoading, isError: orderError } = useQuery({
    queryKey: ["order", orderId],
    queryFn: () => getOrderById(orderId),
    enabled: !!orderId,
  });

  // ✅ Obtener la cuenta del comprador SOLO si existe `buyerAccountId`
  const { data: buyerAccount, isLoading: buyerLoading, isError: buyerError } = useQuery({
    queryKey: ["buyerAccount", order?.buyerAccountId],
    queryFn: () => getBuyerAccountById(order?.buyerAccountId),
    enabled: !!order?.buyerAccountId,
  });

  // 📌 Manejo de estados
  if (orderLoading || buyerLoading) return <Spinner animation="border" className="text-primary" />;
  if (orderError) return <div className="alert alert-danger">Error cargando la orden.</div>;
  if (buyerError) return <div className="alert alert-danger">Error cargando la cuenta del comprador.</div>;

  // ✅ Ya podemos acceder a `order` y `buyerAccount`
  console.log("Orden recibida:", order);
  console.log("Cuenta del comprador:", buyerAccount);

  // 🔥 Construcción de la URL de la imagen
  const imageURL = order?.orderType === "BUYER"
    ? `${process.env.REACT_APP_API_URL}/screenshots/buyers/${buyerAccount?.buyerEmail}/${orderId}.jpg`
    : null;

    const finalURL = order?.orderType === "BUYER"
     ? `${process.env.REACT_APP_API_URL}/screenshots/${orderId}.jpg`
     : null;

  return (
    <div className="container mt-5">
      <Card className="shadow-sm border-0">
        <Card.Header className="d-flex justify-content-between align-items-center">
          <h3 className="m-0">
            <KTIcon iconName="document" className="me-2" />
            Order Info - {buyerAccount?.buyerEmail}
          </h3>
          <Link to="/" className="btn btn-sm btn-primary ">
          <KTIcon iconName='arrow-left' className='fs-4 me-1' />
            Go back
          </Link>
        </Card.Header>

        <Card.Body>
          <table className="table table-hover table-rounded border-gray-300 gy-3 text-white">
            <thead>
              <tr className="fw-bold fs-6 text-gray-800 border-bottom border-gray-300">
                <th className="text-center">Order ID</th>
                <th className="text-center">Screen Start</th>
                <th className="text-center">Screen End</th>
                <th className="text-center">Last Update</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="text-center">{orderId}</td>
                <td className="text-center">
                  {!imageURL || imageError ? (
                    <span className="text-muted">No Image Available</span>
                  ) : (
                    <img
                      src={imageURL}
                      alt="Order Screenshot"
                      className="img-fluid rounded me-5"
                      onError={() => setImageError(true)}
                      style={{ maxWidth: "450px" }}
                    />
                  )}
                </td>
                <td className="text-center">
                  {!finalURL || finalError ? (
                    <span className="text-muted">No Image Available</span>
                  ) : (
                    <img
                      src={finalURL}
                      alt="Order Screenshot"
                      className="img-fluid rounded"
                      onError={() => setFinalError(true)}
                      style={{ maxWidth: "450px" }}
                    />
                  )}
                </td>
                <td className="text-center">
                  {new Date(order.updatedAt).toLocaleString()}
                </td>
              </tr>
            </tbody>
          </table>
        </Card.Body>
      </Card>
    </div>
  );
};

export default OrderInfo;
