import { createContext, useContext, useState, useEffect } from "react";
import { useQuery } from "react-query";
import { getUserByToken } from "./_requests";

type AuthContextType = {
  currentUser: any;
  saveAuth: (token: string) => void;
  logout: () => void;
  refetchUser: () => void;
};

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [authToken, setAuthToken] = useState<string | null>(() => {
    const storedToken = localStorage.getItem("authToken");
    console.log("🔍 Token inicial en localStorage:", storedToken);
    return storedToken;
  });

  // 🔥 Hook para obtener el usuario autenticado
  const { data: currentUser, refetch, isLoading, isError, error } = useQuery(
    "currentUser",
    async () => {
      if (!authToken) {
        console.warn("⚠ No token disponible, evitando llamada a getUserByToken");
        throw new Error("No token disponible");
      }
      console.log("📡 Consultando usuario con token:", authToken);
      return await getUserByToken();
    },
    {
      enabled: Boolean(authToken), // Solo ejecuta la consulta si hay un token válido
      retry: false, // Evita reintentar si falla
    }
  );

  useEffect(() => {
    if (isError) {
      console.error("❌ Error en getUserByToken:", error);
    }
  }, [isError, error]);

  // 🔥 ✅ **Solución: No mostrar nada hasta que termine la consulta**
  if (isLoading) {
    console.log("⏳ Verificando autenticación...");
    return null; // ⛔ No renderiza nada hasta que termine la consulta
  }

  // 🔥 Guardar token en localStorage y actualizar estado
  const saveAuth = (token: string) => {
    console.log("✅ Guardando token en localStorage:", token);
    localStorage.setItem("authToken", token);
    setAuthToken(token);
    refetch(); // 🔄 Refrescar usuario
  };

  // 🔥 Logout (eliminar token y resetear estado)
  const logout = () => {
    console.log("🚪 Cierre de sesión, eliminando token...");
    localStorage.removeItem("authToken");
    setAuthToken(null);
    window.location.href = "/auth/login"; // Redirigir al login
  };

  return (
    <AuthContext.Provider value={{ currentUser, saveAuth, logout, refetchUser: refetch }}>
      {children}
    </AuthContext.Provider>
  );
};


export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) throw new Error("useAuth debe estar dentro de un AuthProvider");
  return context;
};
