import {FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import {Tables} from '../modules/widgets/components/Tables'
import {useAuth} from '../modules/auth'
import { SuppliersTable } from '../modules/widgets/components/Suppliers'

const PrivateRoutes: FC = () => {
  const {currentUser} = useAuth()

  if (!currentUser) {
    return <Navigate to='/auth/login' replace />
  }

  return (
     <Routes>
      <Route element={<MasterLayout />}>
        <Route path="/tables" element={<Tables />} />
        <Route path="/suppliers" element={<SuppliersTable />} />
        <Route path="*" element={<Navigate to="/tables" replace />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
