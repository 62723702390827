import { http } from "./axiosConfig";

export const checkBrowserService = async (id: string, accountType: "buyers" | "suppliers") => {
  try {
    const response = await http.post(`/api/actions/checkBrowser/${id}`, { accountType });
    return response.data;
  } catch (error) {
    console.error("❌ Error en checkBrowserService:", error);
    throw error;
  }
};
