export const getBgClass = (status: string) => {
  const normalizedStatus = status?.toUpperCase(); // Convertir a mayúsculas
  switch (normalizedStatus) {
    case 'BROWSER CLOSED':
    case 'ENABLE TWO FACTOR AUTH':
    case 'ERROR LOGIN':
    case 'ERROR LOGIN - USER/PASS':
    case 'ERROR LOGIN - BACKUP CODE':
    case 'ERROR - NO COINS':
    case 'GENERAL ERROR':
    case 'LOGGED - WAITING TO START':
    case 'LOGGED - COINS UPDATED':
    case 'LOGGING IN...':
    case 'LOGIN ERROR':
    case 'MATCHED':
    case 'NEW ACCOUNT, NO CLUB':
    case 'NEED RELOGIN':
    case 'ORDER COMPLETED':
    case 'PAUSED BY BOT ADMIN':
    case 'READY':
    case 'TM LOCKED. UNLOCK IT!':
    case 'TRANSFER LIST FULL':
    case 'TRANSFERING...':
    case 'WRONG PLATFORM':
    case 'WEBAPP MULTIPLE SESSION LOGGED':
      return 'bg-custom-black';
    default:
      return '';
  }
};

export const getStatusStyle = (status: string) => {
  const normalizedStatus = status?.toUpperCase(); // Convertir a mayúsculas
  switch (normalizedStatus) {
    case 'LOGGED - WAITING TO START':
    case 'LOGGED - COINS UPDATED':
      return { color: 'green' };
    case 'BANNED FROM MARKET':
    case 'BROWSER CLOSED':
    case 'CONSOLE LOGGED IN':
    case 'ENABLE TWO FACTOR AUTH':
    case 'ERROR - NO COINS':
    case 'ERROR LOGIN':
    case 'ERROR LOGIN - USER/PASS':
    case 'ERROR LOGIN - BACKUP CODE':
    case 'GENERAL ERROR':
    case 'PLAYER NOT SOLD':
    case 'PROXY_ERROR':
    case 'LOGIN ERROR':
    case 'NEW ACCOUNT, NO CLUB':
    case 'NEED RELOGIN':
    case 'SERVERS_SLOW':
    case 'TM LOCKED. UNLOCK IT!':
    case 'TM WITHOUT ACCESS. NO +INFO':
    case 'TRANSFER LIST FULL':
    case 'WATCH LIST FULL':
    case 'WRONG PLATFORM':
    case 'WEBAPP MULTIPLE SESSION LOGGED':
      return { color: '#FF5733' };
    case 'PAUSED BY BOT ADMIN':
      return { color: 'orange', fontWeight: 'bold' };
    case 'READY':
    case 'ORDER COMPLETED':
      return { color: '#00FF88' };
    case 'LOGGING IN...':
    case 'TRANSFERING...':
    case 'MATCHED':
      return { color: '#006FFF' };
    default:
      return {};
  }
};
