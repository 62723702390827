// hooks/frontendActions/useHideBuyerAccount.js
import { useMutation, useQueryClient } from 'react-query'
import { http } from '../../../app/services/axiosConfig'

const hideBuyerAccount = async (id) => {
   
  const response = await http.put(`/api/buyerAccounts/removeBuyer/${id}`)
  return response.data
}

const useHideBuyerAccount = () => {
  const queryClient = useQueryClient()

  return useMutation(hideBuyerAccount, {
    onSuccess: () => {
      queryClient.invalidateQueries('buyerAccounts')
    },
  })
}

export default useHideBuyerAccount
