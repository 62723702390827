import { http } from "../axiosConfig";

export const fetchBuyerTransactions = async (buyerId) => {
  try {
    const { data } = await http.get(`/api/transactions/buyer/${buyerId}/transactions`);
    return data;
    
  } catch (error) {
    console.error("Error al obtener buyerTransactions:", error);
    throw error;
  }
};

