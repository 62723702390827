import { http } from "../axiosConfig";

export const fetchBuyerAccounts = async (page = 1, limit = 10, search = '') => {
  try {
    const { data } = await http.get(`/api/buyerAccounts?page=${page}&limit=${limit}&search=${search}`);
    return data;
  } catch (error) {
    console.error("Error al obtener buyerAccounts:", error);
    throw error;
  }
};
