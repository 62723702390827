import { FC, ReactNode, useEffect, useState } from "react";
import { useAuth } from "./Auth";

type AuthInitProps = {
  children: ReactNode;
};

const AuthInit: FC<AuthInitProps> = ({ children }) => {
  const { currentUser, refetchUser } = useAuth();
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    const fetchUser = async () => {
      await refetchUser(); // ✅ Espera a que termine la llamada
      setChecked(true);
    };

    if (!currentUser && !checked) {
      fetchUser();
    }
  }, [currentUser, checked, refetchUser]);

  return <>{children}</>;
};

export { AuthInit };
