import { useQuery } from "react-query";
import { fetchBuyerTransactions } from "../../app/services/buyerServices/buyerTransactionService";

const useBuyerTransactions = (buyerId) => {
  return useQuery({
    queryKey: ["buyerTransactions", buyerId], // 🔥 Clave única con buyerId
    queryFn: () => fetchBuyerTransactions(buyerId),
    staleTime: 1000 * 60 * 5, // Cache de 5 minutos
    refetchOnWindowFocus: false, // No refetch al cambiar de pestaña
    enabled: !!buyerId, // 🔥 Solo ejecuta la petición si hay un buyerId válido
  });
};

export default useBuyerTransactions;
