import { useMutation } from "react-query";
import { reloginService } from "../../../app/services/reloginService";
import toast from "react-hot-toast";

const useRelogin = () => {
  console.log("⚡ useRelogin se ejecutó");

  return useMutation(
    ({ email, accountType }: { email: string; accountType: "buyers" | "suppliers" }) => {
      console.log("📡 Enviando petición a reloginService con:", email, accountType);
      return reloginService(email, accountType);
    },
    {
      onSuccess: () => {
        console.log("✅ Re-login exitoso");
        // toast.success("Status changed to READY");
      },
      onError: (error) => {
        console.error("❌ Error changing status:", error);
        // toast.error("Failed to change status");
      },
    }
  );
};

export default useRelogin;
